import React, { FC, useEffect } from 'react'
import { Col, Row, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { useLazyGetDocumentsQuery } from '../../services/document.api'
import { FirmNames } from '../../configs/enums/firmNames'
import { DocumentTypes } from '../../configs/enums/documentTypes'
import { FilePdfFilled } from '@ant-design/icons'
import { transformDate } from '../../helpers/transformDate'
import s from './Documents.module.scss'

interface DataType {
  key: number
  number: string
  type: string
  firmName: string
  date: string
  link: string
  collection: string
}

const columns: ColumnsType<DataType> = [
  {
    title: '№ документа',
    dataIndex: 'number',
    render: (text: string, record) => {
      return <a download href={record.link}>
        <Row align={'middle'}>
          <Col span={4}>
            <FilePdfFilled color={'#4D92EE'}/>
          </Col>
          <Col span={20}>
            {text}
          </Col>
        </Row>
      </a>
    }
  },
  {
    title: 'Вид документа',
    dataIndex: 'collection',
  },
  {
    title: 'Юр.лицо',
    dataIndex: 'firmName',
  },
  {
    title: 'Дата создания',
    dataIndex: 'date',
  },
]

interface IProps {
  orderIRI: string
}

const Documents: FC<IProps> = ({orderIRI}) => {
  const [getDoc, {data}] = useLazyGetDocumentsQuery()

  useEffect(() => {
    getDoc({product: orderIRI, page: 1})
    // eslint-disable-next-line
  }, [orderIRI])

  return (
    <div className={s.documents}>
      <Table columns={columns}
             dataSource={data
               && data.map(item => ({
                 key: item.id,
                 number: item.name,
                 type: item.type,
                 firmName: FirmNames[item.firmName],
                 date: transformDate(item.updatedAt, true),
                 link: item.link,
                 collection: DocumentTypes[item.collection]
               }))
             }
             pagination={false}
             bordered
             tableLayout={'fixed'}
      />
    </div>
  )
}

export default Documents