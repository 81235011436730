export const optionTypeDocument = [
  {value: 'phisycal_first', label: 'Договор физ. лица'},
  {value: 'juridical_first', label: 'Договор юр. лица'},
  {value: 'juridical_last', label: 'Пакет закрывающих документов, для юр.лиц'},
  {value: 'act', label: 'Акт'},
  {value: 'cheque', label: 'Чек на доставку'},
  {value: 'invoice', label: 'Накладная'},
  {value: 'account', label: 'Счет на оплату'},
]
export const optionTypeDocumentLiveline = [
  {value: 'live_line_regions', label: 'ЛИВ ЛАЙН РЕГИОНЫ'},
  {value: 'live_line_sp', label: 'ЛИВ ЛАЙН СПБ'},
]