import React, { FC, useState } from 'react'
import { Button, Col, Modal, Row, Space } from 'antd'
import SelectCustom from '../../ui/SelectCustom/SelectCustom'
import s from '../../modules/OrderCreating/components/Goods/components/OrderHeader/OrderHeader.module.scss'
import { optionTypeDocument, optionTypeDocumentLiveline } from '../../configs/documents/optionTypeDocument'
import { optionNameCompany } from '../../configs/documents/optionNameCompany'

interface IProps {
  open: boolean
  generateDocument: (company: string, document: string) => void
  handleCancel: () => void
  isLoadingGenerateDoc: boolean
}

const GenerateDocument: FC<IProps> = ({
                                        open,
                                        generateDocument,
                                        handleCancel,
                                        isLoadingGenerateDoc,
                                      }) => {

  const [company, setCompany] = useState<string>('liveline')
  const [document, setDocument] = useState('phisycal_first')

  const onChangeComp = (e: React.ChangeEvent<HTMLSelectElement>) => {
    // @ts-ignore
    if (e === company) return
    setCompany(`${e}`)
    setDocument('phisycal_first')
  }
  const onChangeDoc = (e: React.ChangeEvent<HTMLSelectElement>) => setDocument(`${e}`)

  return (
    <Modal
      open={open}
      closable={false}
      centered
      footer={[
        <Button key="back" onClick={handleCancel}>
          Отмена
        </Button>,
        <Button key="submit"
                type="primary"
                onClick={() => generateDocument(company, document)}
                loading={isLoadingGenerateDoc}
        >
          Сформировать
        </Button>
      ]}
      bodyStyle={{zIndex: 1000}}
    >
      <Space size={16} direction={'vertical'} style={{width: '100%'}}>
        <Row align="middle">
          <Col span={8} flex={2}>Компания</Col>
          <Col span={16}>
            <SelectCustom
              className={s.select}
              heightOptions={44}
              defaultValue={company}
              options={optionNameCompany}
              onSelect={onChangeComp}
            />
          </Col>
        </Row>
        <Row align="middle">
          <Col span={8}>Вид документа</Col>
          <Col span={16}>
            <SelectCustom
              style={{width: '100%'}}
              heightOptions={44}
              value={document}
              defaultValue={document}
              options={company === 'liveline' ? [...optionTypeDocumentLiveline, ...optionTypeDocument] : optionTypeDocument}
              onSelect={onChangeDoc}
            />
          </Col>
        </Row>
      </Space>
    </Modal>
  )
}

export default GenerateDocument