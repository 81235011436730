import React, { FC, useEffect, useState } from 'react'
import {
  AutoComplete,
  Button,
  Col,
  ConfigProvider,
  Form,
  FormListFieldData,
  Input,
  InputNumber,
  Modal,
  notification,
  Row,
  Space,
  Typography
} from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import {
  useGetProductsQuery,
  useLazyGetProductPricesQuery,
  useLazyGetProductsQuery
} from '../../../../../../../services/product.api'
import { IProduct } from '../../../../../../../models/IProduct'
import { LabeledValue } from 'antd/es/select'
import { useAppSelector } from '../../../../../../../hooks/redux'
import { getIsOrderEdit, getOrderToEdit } from '../../../../../../../store/reducers/order/order.slice'
import s from '../Good.module.scss'

const {Text} = Typography

interface IProps {
  field: FormListFieldData
  indexField: number
  goodsIndex: number
  removeField: (index: number | number[]) => void
  sendOnBlurGoods: () => void
  deleteGoodOption: (id: number) => void
}

const RenderGoodItem: FC<IProps> = (props) => {
  const {
    field, goodsIndex, indexField,
    removeField, sendOnBlurGoods,
    deleteGoodOption
  } = props
  const isEditable = useAppSelector(getIsOrderEdit)
  const orderToEdit = useAppSelector(getOrderToEdit)
  const [isModalDeleteGoodOption, setIsModalDeleteGoodOption] = useState(false)
  const [isOpenSearch, setIsOpenSearch] = useState(false)
  const [productsList, setProductsList] = useState<IProduct[]>([])
  const [api, contextHolder] = notification.useNotification({
    placement: 'topRight'
  })
  const [
    getProducts, {data: products, isSuccess: isSuccessProducts}
  ] = useLazyGetProductsQuery()
  const {data: dataProducts} = useGetProductsQuery(null)
  const [getProductPrice, {data: productPrice}] = useLazyGetProductPricesQuery()
  const form = Form.useFormInstance()
  const goods = Form.useWatch('goods', form)
  // const good = goods[goodsIndex]?.good
  const orderType = Form.useWatch(`orderType`, form)
  const productId = form.getFieldValue(['goods', goodsIndex, 'good', indexField, 'productId'])
  const productName = form.getFieldValue(['goods', goodsIndex, 'good', indexField, 'productName'])

  //При запросе цены type single(розница) или wholesale(опт)
  useEffect(() => {
    if (isEditable && !form.isFieldsTouched()) {
      form.setFieldValue(['goods', goodsIndex, 'good', indexField, 'price'],
        (indexField === 0)
          ? Math.round(+orderToEdit?.productOrderItems[goodsIndex]?.price)
          : Math.round(+orderToEdit?.productOrderItems[goodsIndex]?.productOrderItemOptions[indexField - 1]?.price)
      )
    } else if (productPrice?.length && !isEditable) {
      form.setFieldValue(['goods', goodsIndex, 'good', indexField, 'price'], productPrice[0]?.price || '')
    }
    // eslint-disable-next-line
  }, [productPrice])
  useEffect(() => {
    if (goods) {
      const good = goods[goodsIndex]?.good
      const price = good[field.name]?.price
      const quantity = good[field.name]?.quantity
      const discount = good[field.name]?.discount
      const productIRI = good[field.name].productIRI
      const productSize = goods[goodsIndex]?.size
      const materialPrice = goods[goodsIndex]?.price
      const optionIRI = good[indexField].productIRI
      const optionName = good[indexField].productName
      const queryType = ['retail', 'store'].includes(orderType) ? 'single' : 'wholesale'

      if (!isEditable && indexField === 0 && productIRI && productSize && materialPrice && orderType !== 'personal') {
        getProductPrice({
          product: productIRI,
          productSize: productSize,
          materialPrice: `${materialPrice}`,
          type: queryType
        })
      } else if (indexField > 0 && (optionIRI || optionName) && orderType !== 'personal') {
        getProductPrice({
          product: optionIRI || optionName,
          type: queryType,
          materialPrice: `${materialPrice}`
        })
      }

      const comp = Number(price) * Number(quantity)
      const compSum = () => {
        const result = (discount && quantity && price)
          ? (comp - (comp * +discount) / 100).toFixed(0)
          : comp.toFixed(0)

        return !isNaN(+result) ? result : ''
      }

      form.setFieldValue(['goods', goodsIndex, 'good', field.name, 'cost'], comp || '')
      form.setFieldValue(['goods', goodsIndex, 'good', field.name, 'sum'], compSum() || '')
    }
    // eslint-disable-next-line
  }, [goods, orderType])
  useEffect(() => {
    if (products?.length && isSuccessProducts) {
      setProductsList(products)
    } else {
      setProductsList([])
    }
    // eslint-disable-next-line
  }, [products])
  // useEffect(() => {
  // if (isSuccessDeleteOption) {
  //   removeField(indexField)
  // }
  // }, [isSuccessDeleteOption])

  const handleSearch = (newValue: string) => {
    if (newValue && orderType !== 'personal') {
      if (indexField === 0) {
        getProducts({name: newValue, type: 'main'})
      } else {
        getProducts({name: newValue, type: 'option'})
      }
    }
  }

  // при изменении value продукта удаляем его IRI
  const onChangeProductName = (e: any) => {
    form.setFieldValue(['goods', goodsIndex, 'good', field.name, 'productIRI'], null)
  }
  const onSelect = (value: string | number | LabeledValue, option: any) => {
    form.setFieldValue(['goods', goodsIndex, 'good', field.name, 'productIRI'], option.key)
    setIsOpenSearch(false)
  }
  const onFocusProduct = () => {
    if (orderType !== 'personal') {
      setIsOpenSearch(true)
    }
  }
  const onBlurProduct = () => {
    setIsOpenSearch(false)
    sendOnBlurGoods()
  }

  const onDeleteGoodOption = async () => {
    setIsModalDeleteGoodOption(false)
    try {
      const res: any = await deleteGoodOption(productId)
      console.log(res)
      if ('error' in res) {
        api.error({
          message: `Не удалось удалить! Статус: ${res?.error?.status}`,
          duration: 3
        })
      } else {
        removeField(indexField)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const onRemoveField = () => {
    const productIdForDelete = goods[goodsIndex]?.good?.[field.name]?.productId
    if (isEditable && productIdForDelete) {
      setIsModalDeleteGoodOption(true)
    } else {
      removeField(indexField)
    }
  }

  return (
    <>
      {contextHolder}
      <Row gutter={[12, 12]}>
        <Col flex={'56px'}>
          <Form.Item label={indexField === 0 ? <>№</> : ''}>
            <Input disabled={true} className={s.goodNumber} value={indexField + 1}/>

          </Form.Item>
        </Col>
        <Col flex={'1 0 286px'}>
          <ConfigProvider theme={{
            token: {
              controlHeight: 15,
            },
            components: {
              Select: {
                controlHeight: 44
              },
            },
          }}>
            <Form.Item name={[field.name, 'productName']}
              // style={{marginBottom: '12px'}}
                       rules={[{required: true, message: 'Обязательное поле'}]}
                       label={indexField === 0 ? <>Наименование товара<Text type={'danger'}>*</Text></> : ''}
                       labelAlign={'left'}
            >
              <AutoComplete
                open={isOpenSearch}
                className={s.input}
                placeholder={'Выберите товар'}
                options={
                  productsList
                    ? productsList.map((item) => ({key: item.productIRI, value: item.name, label: item.name}))
                    : dataProducts && dataProducts.map((item) => ({
                    key: item.productIRI,
                    value: item.name,
                    label: item.name
                  }))
                }
                onSearch={handleSearch}
                onSelect={onSelect}
                backfill={true}
                defaultValue={''}
                onFocus={onFocusProduct}
                onBlur={onBlurProduct}
                onChange={onChangeProductName}
              />
            </Form.Item>
          </ConfigProvider>
        </Col>
        <Col flex={'113px'}>
          <Form.Item name={[field.name, 'price']}
                     style={{marginBottom: '12px', alignItems: 'center'}}
                     rules={[{required: true, message: 'Обязательное поле'}]}
                     label={indexField === 0 ? <>Цена<Text type={'danger'}>*</Text></> : ''}
          >
            <Input type={'number'}
                   style={{height: '44px'}}
                   className={s.input}
                   onBlur={sendOnBlurGoods}
            />
          </Form.Item>
        </Col>
        <Col flex={'95px'}>
          <Form.Item name={[field.name, 'quantity']}
                     style={{marginBottom: '12px'}}
                     label={indexField === 0 ? 'Кол-во' : ''}
          >
            <InputNumber min={1} max={99999}
                         size={'large'}
                         className={s.input}
                         style={{width: '100%', height: '44px'}}
                         maxLength={5}
                         onBlur={sendOnBlurGoods}
            />
          </Form.Item>
        </Col>
        <Col flex={'113px'}>
          <Form.Item name={[field.name, 'cost']}
                     style={{marginBottom: '12px'}}
                     label={indexField === 0 ? 'Стоимость' : ''}
          >
            <Input type={'text'}
                   style={{height: '44px'}}
                   className={s.input}
                   disabled={true}
            />
          </Form.Item>
        </Col>
        <Col flex={'95px'}>
          <Form.Item name={[field.name, 'discount']}
                     style={{marginBottom: '12px', display: 'flex', alignItems: 'center'}}
                     label={indexField === 0 ? 'Скидка %' : ''}
          >
            <InputNumber min={1} max={100}
                         maxLength={3}
                         size={'large'}
                         className={s.input}
                         style={{width: '100%', height: '44px'}}
                         onBlur={sendOnBlurGoods}
            />
          </Form.Item>
        </Col>
        <Col flex={'150px'}>
          <Space direction={'horizontal'}>
            <Form.Item name={[field.name, 'sum']}
                       style={{marginBottom: '12px'}}
                       label={indexField === 0 ? 'Стоим. со скидкой' : ''}
            >
              <Input type={'text'}
                     className={s.input}
                     style={{height: '44px'}}
                     disabled={true}
              />
            </Form.Item>
            <Form.Item style={{marginBottom: '12px'}}>
              {
                indexField !== 0 && <Button type={'ghost'}
                                            style={{width: '20px'}}
                                            onClick={onRemoveField}
                                            icon={<CloseOutlined style={{height: '44px'}}/>}
                />
              }
            </Form.Item>
          </Space>
        </Col>
      </Row>

      <Modal
        open={isModalDeleteGoodOption}
        onOk={onDeleteGoodOption}
        onCancel={() => setIsModalDeleteGoodOption(false)}
        okText="Да"
        cancelText="Нет"
        centered
        closable={false}
      >
        Вы действительно хотите удалить {productName}?
      </Modal>
    </>
  )
}

export default RenderGoodItem